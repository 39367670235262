/*
 * @Author: wxy
 * @Describe:
 * @LastEditors: wxy
 * @LastEditTime: 2021-06-21 21:00:25
 */

import axios from "axios";
import { httpConfig } from "../config/http.config"; // http配置
import NProgress from "nprogress"; // Progress 进度条
import { message } from "ant-design-vue";
import { checkValue, getValue, delValue } from "@/common/util/storage";
import { globalConfig } from "../config/global.config";
import route from "@/route/index";

const _axios = axios.create({
  baseURL: httpConfig.url,
  timeout: httpConfig.timeout,
  headers: {
    post: {
      "Content-Type": "application/json",
    },
  },
});

// 请求前
_axios.interceptors.request.use(
  (config) => {
    // console.log("发送前", config);
    // console.log("字段", globalConfig.field);
    if (!config.data) config.data = {};
    if (!config.params) config.params = {};
    if (config.data.requestCustom || config.params.requestCustom) {
      const customConfig =
        config.data.requestCustom || config.params.requestCustom;
      if (!customConfig.noNProgress) {
        NProgress.start(); // 顯示進度條
      }
    } else {
      NProgress.start(); // 顯示進度條
    }
    try {
      // console.log("判斷緩存", checkValue(globalConfig.field.token));
      if (checkValue(globalConfig.field.token)) {
        // console.log("獲取緩存", getValue(globalConfig.field.token));
        config.headers.token = getValue(globalConfig.field.token); //添加Token
      }
    } catch (error) {
      console.error("请求前报错：", error);
    }
    return config;
  },
  (error) => {
    NProgress.done(); // 關閉進度條
    message.error(JSON.stringify(error));
    return Promise.reject(error);
  }
);

// 响应返回前
_axios.interceptors.response.use(
  (response) => {
    NProgress.done(); // 關閉進度條
    let result = response.data;

    if (!result.code) {
      return Promise.resolve(result);
    }

    // 网络错误
    if (response.status !== 200) {
      message.error(JSON.stringify(result.msg));
      return Promise.reject(response);
    }
    // 服务器错误
    if (~httpConfig.errorCode.indexOf(result.code)) {
      if (result.code === "403") {
        delValue(globalConfig.field.token);
        delValue(globalConfig.field.info);
        route.replace({ name: "login" });
        // const router = useRouter();
      }

      message.error(JSON.stringify(result.msg));
      return Promise.reject(result);
    }

    if (~httpConfig.successCode.indexOf(result.code)) {
      return Promise.resolve(result);
    }
    // message.success(JSON.stringify(result.msg));
  },
  (error) => {
    NProgress.done(); // 關閉進度條
    message.error(JSON.stringify(error.message));
    return Promise.reject(error);
  }
);

// 使用法法
export default {
  post(url, data, toast, responseType = "") {
    return new Promise((resolve, reject) => {
      _axios({
        method: "post",
        url,
        data,
        responseType: responseType,
      })
        .then((res) => {
          if (toast) {
            message.success(res.msg);
          }

          // 下载文件用
          if (responseType === "blob") {
            resolve(res);
            return;
          }

          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  get(url, data, toast, responseType = "") {
    return new Promise((resolve, reject) => {
      _axios({
        method: "get",
        url,
        params: data,
        responseType,
      })
        .then((res) => {
          if (toast) {
            message.success(res.msg);
          }
          // 下载文件用
          if (responseType === "blob") {
            resolve(res);
            return;
          }
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
